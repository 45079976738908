import React from 'react';

import Box from 'components/Box';
import Flex from 'components/Flex';
import Grid from 'components/Grid';
import H1 from 'components/H1';
import InnerWrap from 'components/layout/InnerWrap';
import LatestResources from 'components/resources/LatestResources';
import LinkButton from 'components/LinkButton';
import MainLayout from 'components/layout/MainLayout';
import OuterWrap from 'components/layout/OuterWrap';
import OverlayBox from 'components/OverlayBox';
import Span from 'components/Span';
import practiceAreasHeader from 'images/practice-areas-header.jpg';

const SubHeader: React.FC = (props) => {
  return (
    <OuterWrap bg="grey900" color="white" py={5}>
      <Box as="h2" variant="header2Serif" textAlign="center" {...props} />
    </OuterWrap>
  );
};

const PracticeAreasPage: React.FC = () => {
  return (
    <MainLayout
      seoProps={{
        title: 'Practice Areas - Employment Lawyers and Commercial Lawyers',
        pagePath: '/practice-areas/',
      }}
    >
      <OuterWrap
        bg="grey700"
        color="white"
        position="relative"
        sx={{
          backgroundImage: `url(${practiceAreasHeader})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <OverlayBox bg="rgba(0, 0, 0, 0.6)" />
        <InnerWrap textAlign="center" position="relative">
          <H1>Practice Areas</H1>
          <Box variant="header6Serif" mt={3}>
            Corporate&nbsp;&nbsp;|&nbsp;&nbsp;Commercial&nbsp;&nbsp;|&nbsp;&nbsp;Employment
          </Box>
        </InnerWrap>
      </OuterWrap>
      <SubHeader>Corporate & Commercial Law Services</SubHeader>
      <OuterWrap pt={[7, 7]}>
        <InnerWrap>
          <Flex>
            <Box width={['100%', 2 / 3]}>
              <Box as="p" fontWeight="400">
                <Span>PLG</Span> provides a full suite of solicitor-side corporate commercial legal
                services. Whether you are starting a new business or looking to grow or sell an
                existing business, hiring attentive and proactive counsel is vital to meeting the
                competitive demands of carrying on a business. At PLG, we take the time to learn
                about your business and seamlessly integrate innovative and cost-sensitive solutions
                to better help you meet your underlying business objectives, including:
              </Box>
              <ul>
                <li>Asset/Share Purchase Transactions</li>
                <li>Business-to-Business Contracts</li>
                <li>Business Succession Planning</li>
                <li>Incorporations</li>
                <li>Commercial Transactions </li>
                <li>Corporate Bylaws</li>
                <li>Corporate Governance</li>
                <li>Corporate Records</li>
                <li>Corporate Reorganizations, Amalgamations, and Dissolutions</li>
                <li>Employment Agreements</li>
                <li>Franchisor/Franchisee Services</li>
                <li>Goods & Services Purchase and Sale Agreements</li>
                <li>Partnership and Limited Partnership Agreements</li>
                <li>
                  Shareholder & Partnership Agreements, Joint Venture Agreements and other related
                  Arrangements
                </li>
                <li>
                  <i>Income Tax Act</i> S. 85 Rollover and S. 86 Share Exchanges
                </li>
              </ul>
              <LinkButton to="/contact/" bVariant="primary" display="inline-block">
                Become a client
              </LinkButton>
            </Box>
          </Flex>
        </InnerWrap>
      </OuterWrap>
      <SubHeader>Employment Law Services</SubHeader>
      <OuterWrap bg="grey100" pt={6}>
        <InnerWrap>
          <Box as="h3" variant="header3Serif" fontWeight="700">
            Employers
          </Box>
          <Box mt={5}>
            The management side of employment law at PLG provides services to small and medium size
            businesses. For today’s employers, managing a workforce requires complying with local
            labour and employment laws, staying abreast of rapid changes, and retaining top talent
            in one’s respective industry. We recognize that small and medium size businesses don’t
            always have the expertise nor the budget to obtain in-house counsel services. PLG serves
            to fill that market by providing value-driven strategies to both start-ups and developed
            businesses in managing their workplace.
          </Box>
          <Box mt={4}>PLG&#39;s Expertise for Employers Includes:</Box>
          <ul>
            <li>Employment Agreements</li>
            <li>Executive Employment Agreements</li>
            <li>Executive Compensation</li>
            <li>Employee Handbooks</li>
            <li>Duty to Accommodate and Undue Hardship</li>
            <li>Human Rights Issues</li>
            <li>Independent Contractor Agreements</li>
            <li>Just Cause Termination</li>
            <li>Layoffs</li>
            <li>Non-Solicitation and Non-Competition Agreements</li>
            <li>Resignations</li>
            <li>Severance Package Preparation</li>
            <li>Termination Package Preparation</li>
          </ul>
          <LinkButton to="/contact/" bVariant="primary" display="inline-block" mt={3}>
            Become a client
          </LinkButton>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr 1fr']} gridGap={6} mt={6}>
            <Box>
              <Box as="h4" variant="header5Serif" color="darkGold" mb={4}>
                Risk Mitigation
              </Box>
              <p>
                PLG emphasizes risk-mitigation strategies to help employers manage workplace changes
                and avoid costly mistakes. Our strategic advice offers employers with the policies
                and procedures to reduce risk and minimize post-employment obligations.
              </p>
            </Box>
            <Box>
              <Box as="h4" variant="header5Serif" color="darkGold" mb={4}>
                Proactive Planning
              </Box>
              <p>
                With the proper guidance and expertise, a majority of employment law issues can be
                avoided. PLG identifies potential issues before they occur, allowing businesses to
                focus their attention on what truly matters; their clients.
              </p>
            </Box>
            <Box>
              <Box as="h4" variant="header5Serif" color="darkGold" mb={4}>
                Remaining Ahead of the Curve
              </Box>
              <p>
                Ontario’s labour market, and the laws that govern it, are constantly changing. At
                PLG, we are at the forefront of emerging trends in employment law and are dedicated
                to keeping up to date on all major developments and issues that matter to our
                clients.
              </p>
            </Box>
          </Grid>
        </InnerWrap>
      </OuterWrap>
      <OuterWrap pt={6}>
        <InnerWrap>
          <Box as="h3" variant="header3Serif" fontWeight="700">
            Employees
          </Box>
          <Box as="p" mt={5}>
            PLG is experienced in assisting our employee clients on myriad employment law issues.
            Most clients of PLG have received notice that their employment has been terminated and
            they are unsure as to how to proceed. While each case is unique, the vast majority of
            cases demonstrate that employers will provide employees far less severance than what
            they are actually owed. Employees, unless intimately familiar with the latest
            developments in the common law, are usually unaware of this and will leave money on the
            table.
          </Box>
          <Box mt={4}>PLG&#39;s Expertise for Employees Includes:</Box>
          <ul>
            <li>Constructive Dismissal </li>
            <li>Employee and Employer Obligations</li>
            <li>Duty to Accommodate and Undue Hardship</li>
            <li>Fiduciary Obligations </li>
            <li>
              Human Rights (Ontario <i>Human Rights Code</i>, RSO 1990, c. H. 19)
            </li>
            <li>Non-Solicitation, Non-Competition, and Confidentiality Agreements</li>
            <li>Notice Period and Pay in Lieu of Notice</li>
            <li>Prohibited Grounds of Discrimination </li>
            <li>Unjust Dismissal</li>
            <li>Termination Pay and Severance</li>
            <li>Termination for Cause</li>
            <li>Workplace Harassment </li>
            <li>Workplace Privacy</li>
            <li>Wrongful Dismissal</li>
          </ul>
          <LinkButton to="/contact/" bVariant="primary" display="inline-block" mt={3}>
            Become a client
          </LinkButton>
          <Grid gridTemplateColumns={['1fr', '1fr 1fr 1fr']} gridGap={6} mt={5}>
            <Box>
              <Box as="h4" variant="header5Serif" color="darkGold" mb={4}>
                Pre-Employment Advice
              </Box>
              <p>
                The terms and conditions of Employment Agreements, including termination
                entitlements and restrictive covenants can have a significant impact on the
                employment relationship post-employment. Having experienced counsel review
                Employment Agreements ahead of time will ensure the terms and conditions are fully
                understood by the client, reducing the likelihood of surprises in the future and
                minimizing the potential for litigation.
              </p>
            </Box>
            <Box>
              <Box as="h4" variant="header5Serif" color="darkGold" mb={4}>
                Post-Employment Representation
              </Box>
              <p>
                In the event of termination without cause, employees will usually be offered some
                level of severance and often an additional week or two of the employee’s salary in
                exchange for a release. This amount is, more often than not, considerably less than
                what the employee is entitled to; and to the employee’s detriment, this tactic often
                works. PLG advises and represents employees relative to wrongful dismissals and the
                negotiation of severance packages, ensuring their rights are protected and employers
                are held accountable.
              </p>
            </Box>
            <Box>
              <Box as="h4" variant="header5Serif" color="darkGold" mb={4}>
                Results-Driven Approach
              </Box>
              <p>
                PLG takes a reasonable approach when negotiating with employers and opposing
                counsel. We recognize that every successful negotiation requires a sound strategy,
                fulsome preparation, and a steadfast resolve. This is our formula for success, and
                why our clients place their trust in PLG.
              </p>
            </Box>
          </Grid>
        </InnerWrap>
      </OuterWrap>
      <LatestResources wrapProps={{ bg: 'grey100' }} />
    </MainLayout>
  );
};

export default PracticeAreasPage;
