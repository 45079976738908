import React from 'react';

import Grid, { GridProps } from 'components/Grid';
import ResourcePreview, { ResourcePreviewData } from 'components/resources/ResourcePreview';

type Props = GridProps & {
  resources: { node: ResourcePreviewData }[];
};

const ResourceGrid: React.FC<Props> = ({ resources, ...props }) => {
  return (
    <Grid
      gridTemplateColumns={['1fr', '1fr 1fr 1fr']}
      gridColumnGap={30}
      gridRowGap={[60]}
      {...props}
    >
      {resources.map(({ node }) => (
        <ResourcePreview key={node.id} resource={node} />
      ))}
    </Grid>
  );
};

export default ResourceGrid;
