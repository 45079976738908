/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import React from 'react';

import { Resource } from 'types/schema';
import Box from 'components/Box';
import Link from 'components/Link';

export const ResourcePreviewFragment = graphql`
  fragment SanityImage on SanityImageWithAlt {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }
  fragment ResourcePreview on SanityResource {
    id
    title
    textExcerpt
    slug {
      current
    }
    publishedAt(formatString: "MMMM D, YYYY")
    author {
      author {
        name
      }
    }
    mainImage {
      ...SanityImage
      alt
    }
  }
`;

type Props = {
  resource: Resource;
};
const BlogPost: React.FC<Props> = ({
  resource: {
    title,
    textExcerpt,
    slug,
    publishedAt,
    author: {
      author: { name: authorName },
    },
  },
}) => (
  <Link
    to={`/resources/${slug.current}/`}
    border="2px solid"
    borderColor="darkGold"
    px={4}
    py={5}
    sx={{ textDecoration: 'none', '&:hover': { '.title': { textDecoration: 'underline' } } }}
  >
    <Box color="grey700">{authorName}</Box>
    <Box color="grey700" variant="body2">
      {publishedAt}
    </Box>
    <Box as="h4" variant="header5Bold" color="black" className="title" mt={3}>
      {title}
    </Box>
    <Box variant="body1" mt={4}>
      {textExcerpt}
    </Box>
  </Link>
);

export default BlogPost;
