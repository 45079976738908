import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Box from 'components/Box';
import Flex from 'components/Flex';
import InnerWrap from 'components/layout/InnerWrap';
import OuterWrap from 'components/layout/OuterWrap';
import { BoxProps } from 'rebass';
import ResourceGrid from 'components/resources/ResourceGrid';

const THREE_LATEST_POSTS_QUERY = graphql`
  query {
    allSanityResource(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          ...ResourcePreview
        }
      }
    }
  }
`;

type Props = BoxProps & {
  wrapProps?: BoxProps;
};
const LatestPosts: React.FC<Props> = ({ wrapProps, ...props }) => {
  const {
    allSanityResource: { edges: resources },
  } = useStaticQuery(THREE_LATEST_POSTS_QUERY);
  return (
    <OuterWrap {...wrapProps}>
      <InnerWrap>
        <Flex justifyContent="center">
          <Box
            as="h2"
            variant="header2Serif"
            textAlign="center"
            borderBottom="2px solid"
            borderColor="gold"
          >
            Latest Resources
          </Box>
        </Flex>
        <ResourceGrid resources={resources} mt={6} {...props} />
      </InnerWrap>
    </OuterWrap>
  );
};

export default LatestPosts;
